import beautyIcon from './assets/images/beauty.svg'
import fashionIcon from './assets/images/fashion.svg'
import homeIcon from './assets/images/home.svg'
import lifestyleIcon from './assets/images/lifestyle.svg'
import facebookLogo from './assets/images/social/facebook-white.svg'
import instagramLogo from './assets/images/social/instagram-white.svg'
import pinterestLogo from './assets/images/social/pinterest-white.svg'
import twitterLogo from './assets/images/social/twitter-white.svg'
import youTubeLogo from './assets/images/social/youTube-white.svg'

export const title = 'Chirpyest'

if (!process.env.GATSBY_API_URL) {
  throw new Error('Environment variable GATSBY_API_URL does not exist!')
}

export const IS_DEVELOPMENT = process.env.GATSBY_IS_DEVELOPMENT === 'true'

export const baseURL = process.env.GATSBY_API_URL

export const youtubeVideoURL = 'https://www.youtube.com/embed/5dSMt6ngyH0'

export const printestDefaultImageURL =
  'https://chirpyest.com/src/favicon-512x512.png'

export const HASH_SIGN_IN = '#sign-in'

export const COLORS = {
  lightGrey: '#ABABAB',
  whiteStain: '#FAFBFD',
  dangerRed: '#FF3860',
  ashGrey: '#CDCDCD',
  black: '#000000',
  watermelonPink: '#F27870',
  gainsboro: '#ddd',
  infoBlue: '#0D3C61',
  successGreen: 'green',
  white: '#FFFFFF',
  darkGrey: '#737373',
  darkGrey2: '#000000DE',
  lightBlack: '#232323',
  lightTeal: '#BFD3D0',
  whiteSmoke: '#F6F6F6',
  coolTeal: '#2E7C86',
}

export const TYPOGRAPHY_SIZES = {
  big: 'big',
  small: 'small',
}

export const TYPOGRAPHY_ALIGNMENT = {
  left: 'left',
  right: 'right',
}

export const ENDPOINTS = {
  swapFolderPosition: '/api/v1/shopping-boards/folders/update-position',
  signUp: '/api/v1/auth/signup',
  verifyCaptcha: '/api/v1/auth/verify-recaptcha',
  socialSignUp: '/api/v1/auth/social-signup',
  brandsList: '/api/v1/affiliate-networks/brands',
  shortenLink: 'api/v1/brands/shortLinks',
  user: 'api/v1/users/:id/profile',
  userPayments: 'api/v1/users/:id/payments',
  overPayments: 'api/v1/users/:id/over-payments',
  contactUs: 'api/v1/support',
  newsLetter: 'api/v1/newsletter',
  settings: '/api/v1/settings',
  iosBanners: '/api/v1/settings/prompt-text',
  shoppingBoard: '/api/v1/shopping-boards',
  shoppingBoardNonUser: '/api/v1/shopping-boards/:id',
  updatePositionShoppingBoardItem:
    '/api/v1/shopping-board-items/update-position',
  deleteShoppingBoardItem: '/api/v1/shopping-board-items/:id',
  addItemToShoppingBoard: '/api/v1/shopping-board-items-by-link',
  addItemToShoppingBoardForm: '/api/v1/shopping-board-items-manually',
  blogs: '/api/v1/blogs',
  banner: '/api/v1/banner',
  categories: '/api/v1/categories',
  categoryTopProducts: '/api/v1/top-category-products/:categoryPage',
  featuredBrands: '/api/v1/brands/featured',
  brandById: 'api/v1/brands/brandId',
  brandProducts: 'api/v1/brands/brandId/products',
  trending: '/api/v1/trending',
  topProducts: '/api/v1/top-products',
  productById: '/api/v1/products?productId={id}',
  categoryByName: '/api/v1/categories/:categoryName',
  categoryListing: '/api/v1/categories/:categoryName/products',
  allCategory: '/api/v1/products/all',
  getInstagramInfo: '/api/v1/shopping-board-items/instagram',
  getInstagramUserPic: '/api/v1/instagram-profile/username',
  getInstagramImage: '/api/v1/shopping-board-items/base64',
  addInstagramImage: '/api/v1/shopping-board-items/instagram',
  addToShoppingBoard: '/api/v1/shopping-board-items',
  salesChannel: '/api/v1/sales-channel',
  sbFolders: '/api/v1/shopping-boards/:username/folders',
  createSBFolder: '/api/v1/shopping-boards/folders',
  SBFolder: '/api/v1/shopping-boards/folders/:id',
  getSBFolder: '/api/v1/shopping-boards/folder/:id',
  addProductsToFolder: '/api/v1/shopping-boards/folder/:id/items',
  SBUpdatePosition: '/api/v1/shopping-board-items/update-position',
  SBFolderUpdatePosition:
    '/api/v1/shopping-boards/folder/:folderId/update-position',
  deleteItemFromFolder: '/api/v1/shopping-boards/folder/items/:folderItemId',
  getUserRecruits: '/api/v1/dashboard/signed-up-by-you',
  setFolderPrivateToken: '/api/v1/shopping-boards/folder/:id/share',
  getTheChirpyest: '/api/v1/the-chirpyest',
  isRequestingPro: '/api/v1/users/:id/request-pro',
  cms: '/api/v1/cms/:name',
  cmsPro: '/api/v1/cms/pro',
  yourRecruits: '/api/v1/dashboard/your-recruits',
  deleteProduct: '/api/v1/admin/delete-product',
  editPage: '/api/v1/edit-pages',
  forwardEditPageToShoppingBoard:
    '/api/v1/edit-pages/:id/forward-to-shopping-board',
  getProductAllimages: '/api/v1/product-all-images',
  getSignedByYourRecruits: '/api/v1/dashboard/signed-up-by-your-recruits',
  parseProduct: '/api/v1/parse-product-link',
  track: 'api/v1/track',
  trackCs: 'api/v1/click-stream-web',
  klaviyoTrigger: '/api/v1/klaviyo/trigger',
  increaseVisitsByOne: '/api/v1/users/:id/update-visit-score',
  joinPages: '/api/v1/join-pages',
}

export const ROUTES = {
  home: '/',
  login: '/login',
  join: '/join',
  joinWithModal: '/join?popup=1',
  brands: '/brands',
  about: '/about',
  help: '/help#tips',
  faq: '/help#faqs',
  cashback: '/user/dashboard#cashback',
  settings: '/user/dashboard#settings',
  paypalSettings: '/user/dashboard#paypalSettings',
  paymentInfo: '/user/dashboard#paymentInfo',
  dashboardChirpyestPro: '/user/dashboard#chirpyest-pro',
  signIn: '/#sign-in',
  user: '/user',
  mainDashboard: '/dashboard',
  blog: 'https://chirpyest.com/blog',
  contactUs: '/company#contact-us',
  terms: '/company#terms',
  privacy: '/company#privacy',
  member: '/user/member',
  mainMember: '/member',
  featuredBrands: '/featured-brands/',
  mainPersonalInfo: '/personal-info',
  personalInfo: '/user/personal-info',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  mainCreateShareLink: '/share-with-friends',
  createShareLink: '/user/share-with-friends',
  howItWorks: '/how-it-works',
  gate: '/gate',
  category: '/category',
  landing: '/landing',
  chirpyestPro: '/chirpyest-pro',
  theChirpyest: '/chirpyest-community',
  browserExtension: '/best-cash-back-browser-extension-website',
  search: '/search',
  board: '/s',
  mainBoard: '/s/:userName',
  fashionCategory: '/category/fashion',
  homeCategory: '/category/home',
  beautyCategory: '/category/beauty',
  lifestyleCategory: '/category/lifestyle',
  yourAccount: '/user/dashboard',
  shop: '/shop',
  joina: '/joina',
  error: '/404',
  myShoppingBoardProxy: '/my-shopping-board',
}

export const FONTS = {
  Schnyder: {
    SchnyderLLight: 'Schnyder L Light',
    SchnyderLBoldItalic: 'Schnyder L Bold Italic',
    SchnyderLBold: 'Schnyder L Bold',
    SchnyderLDemiItalic: 'Schnyder L Demi Italic',
    SchnyderLDemi: 'Schnyder L Demi',
    SchnyderLLightItalic: 'Schnyder L Light Italic',
    SchnyderMBoldItalic: 'Schnyder M Bold Italic',
    SchnyderMBold: 'Schnyder M Bold',
    SchnyderMDemiItalic: 'Schnyder M Demi Italic',
    SchnyderMDemi: 'Schnyder M Demi',
    SchnyderMLightItalic: 'Schnyder M Light Italic',
    SchnyderMLight: 'Schnyder M Light',
    SchnyderSBoldItalic: 'Schnyder S Bold Italic',
    SchnyderSBold: 'Schnyder S Bold',
    SchnyderSDemiItalic: 'Schnyder S Demi Italic',
    SchnyderSLightItalic: 'Schnyder S Light Italic',
    SchnyderSLight: 'Schnyder S Light',
    SchnyderXLBoldItalic: 'Schnyder XL Bold Italic',
    SchnyderXLBold: 'Schnyder XL Bold',
    SchnyderXLDemiItalic: 'Schnyder XL Demi Italic',
    SchnyderXLDemi: 'Schnyder XL Demi',
    SchnyderXLLightItalic: 'Schnyder XL Light Italic',
    SchnyderXLLight: 'Schnyder XL Light',
  },
  Graphik: {
    GraphikBlack: 'Graphik-Black',
    GraphikBlackItalic: 'Graphik-BlackItalic',
    GraphikBold: 'Graphik-Bold',
    GraphikBoldItalic: 'Graphik-BoldItalic',
    GraphikExtralight: 'Graphik-Extralight',
    GraphikExtralightItalic: 'Graphik-ExtralightItalic',
    GraphikLight: 'Graphik Light',
    GraphikLightItalic: 'Graphik-LightItalic',
    GraphikMedium: 'Graphik Medium',
    GraphikMediumItalic: 'Graphik-MediumItalic',
    GraphikRegular: 'Graphik Regular',
    GraphikRegularItalic: 'Graphik-RegularItalic',
    GraphikSemibold: 'Graphik-Semibold',
    GraphikSemiboldItalic: 'Graphik-SemiboldItalic',
    GraphikSuper: 'Graphik-Super',
    GraphikSuperItalic: 'Graphik-SuperItalic',
    GraphikThin: 'Graphik-Thin',
    GraphikThinItalic: 'Graphik-ThinItalic',
  },
}

//TODO: add SM links
export const SOCIAL_MEDIA_LINKS = {
  instargram: 'https://www.instagram.com/chirpyestofficial/',
  tiktok: 'https://www.tiktok.com/@chirpyest',
  pinterest: 'https://www.pinterest.com/chirpyest/',
  facebook: 'https://www.facebook.com/chirpyest/',
  twitter: 'https://twitter.com/chirpyest',
}

export const BREAKPOINTS = {
  xsm: 320,
  sm: 480,
  xmd: 576,
  md: 768,
  mdd: 960,
  lg: 1024,
  xl: 1200,
  xml: 1360,
  xxl: 1400,
  xlg: 1600,
  xxlg: 1840,
}

export const WIDTH_DIMENSIONS = {
  max: 1650,
}

export const regExps = {
  instagramLink: new RegExp('https://www.instagram.com/*'),
}

export const socialMedia = {
  instagram: {
    logo: instagramLogo,
    label: 'https://www.instagram.com/username',
  },
  // facebook: { logo: facebookLogo, label: 'facebook.com/username' },
  // twitter: { logo: twitterLogo, label: 'twitter.com/username' },
  // pinterest: { logo: pinterestLogo, label: 'pinterest.com/username' },
  // youTube: { logo: youTubeLogo, label: 'youtube.com/username' },
}

export const socialMediaArray = [
  { logo: facebookLogo, label: 'facebook.com/username' },
  { logo: twitterLogo, label: 'twitter.com/username' },
  { logo: pinterestLogo, label: 'pinterest.com/username' },
  { logo: youTubeLogo, label: 'youtube.com/username' },
]

export const CASHBACK_RANGES = [
  {
    id: 1,
    min: 0,
    max: 2,
    range: 'less than 2%',
  },
  {
    id: 2,
    min: 2,
    max: 5,
    range: '2-5%+',
  },
  {
    id: 3,
    min: 6,
    max: 10,
    range: '6-9%+',
  },
  {
    id: 4,
    min: 10,
    max: Infinity,
    range: '10%+',
  },
]

export const INFO_EMAIL = 'info@chirpyest.com'

const generateAlphabets = () => {
  const alphabets = []
  const start = 'A'.charCodeAt(0)
  const last = 'Z'.charCodeAt(0)
  for (let i = start; i <= last; ++i) {
    alphabets.push(String.fromCharCode(i))
  }
  return alphabets
}

export const ALPHABET_LIST: string[] = generateAlphabets()

// products have network id while brands have network name
export const BRANDS_NETWORK_TYPES: {
  [key: string]:
    | 'commissionJunction'
    | 'rakuten'
    | 'impactRadius'
    | 'partnerize'
    | 'shareASale'
    | 'pepperjam'
    | 'awin'
    | 'ebay'
} = {
  commissionJunction: 'commissionJunction',
  rakuten: 'rakuten',
  impactRadius: 'impactRadius',
  partnerize: 'partnerize',
  shareASale: 'shareASale',
  pepperjam: 'pepperjam',
  awin: 'awin',
  ebay: 'ebay',
  1: 'impactRadius',
  2: 'rakuten',
  3: 'commissionJunction',
  4: 'partnerize',
  5: 'shareASale',
  6: 'pepperjam',
  7: 'awin',
  8: 'ebay',
}

export const BRANDS_CATEGORIES: any = {
  all: { value: 1, label: 'all' },
  fashion: {
    value: 2,
    label: 'fashion',
  },
  beauty: {
    value: 3,
    label: 'beauty',
  },
  home: {
    value: 4,
    label: 'home',
  },
  lifestyle: {
    value: 5,
    label: 'lifestyle',
  },
  travel: {
    value: 6,
    label: 'travel',
  },
  fitness: {
    value: 7,
    label: 'fitness',
  },
  tech: {
    value: 8,
    label: 'tech',
  },
  pets: {
    value: 9,
    label: 'pets',
  },
  kids: {
    value: 10,
    label: 'kids',
  },
  'health & wellness': {
    value: 11,
    label: 'health & wellness',
  },
  'eco-friendly': {
    value: 12,
    label: 'eco-friendly',
  },
  luxury: {
    value: 13,
    label: 'luxury',
  },
  jewelry: {
    value: 14,
    label: 'jewelry',
  },
}

export const EXTENSION_ID =
  process.env.GATSBY_EXTENSION_ID || 'neepmjdfehbnjjahgjiaadbedkhehgnd'

export const videos = {
  extensionVideo1: {
    mp4:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/1/animation_1_v5.mp4',
    webM:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/1/animation_1_v5.webm',
  },
  extensionVideo2: {
    mp4:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/2/animation_2_v3.mp4',
    webM:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/2/animation_2_v3.webm',
  },
  extensionVideo3: {
    mp4:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/3/Chirpyest_animation_3_v3.mp4',
    webM:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/3/Chirpyest_animation_3_v3.webm',
  },
  extensionVideo4: {
    mp4:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/4/Chirpyest_animation_4_v3.mp4',
    webM:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/4/Chirpyest_animation_4_v3.webm',
  },
  comingSoon: {
    mp4:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/coming-soon.mp4',
    webM:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/coming-soon.mp4',
  },
  makeMoney: {
    mp4:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/make-money/make-money-gif.mp4',
    webM:
      'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/make-money/make-money-gif.webm',
  },
}

export const SHOP_MENU_CONFIG = {
  fashion: {
    title: 'fashion',
    link: '#',
    icon: fashionIcon,
  },
  home: {
    title: 'home',
    link: '#',
    icon: homeIcon,
  },
  beauty: {
    title: 'beauty',
    link: '#',
    icon: beautyIcon,
  },
  lifestyle: {
    title: 'lifestyle',
    link: '#',
    icon: lifestyleIcon,
  },
}

export const IOS_APP_LINK =
  'https://apps.apple.com/il/app/chirpyest-cash-back-shopping/id6447595289'

// NOTE: Please update the Documentation if any new events are added
// https://www.notion.so/Events-07f9c3e4189a42d398dba1ce5dd3449b?pvs=4
export const CSEvents = {
  // Product
  clickProduct: 'CLICK_PRODUCT',
  viewProduct: 'VIEW_PRODUCT',
  shareProduct: 'SHARE_PRODUCT',

  // Extension events
  openExtension: 'OPEN_EXTENSION',
  closeExtension: 'CLOSE_EXTENSION',

  // Navigation events
  clickHomeNavBtn: 'CLICK_HOME_NAV_BTN',
  clickSearchBrandsNavBtn: 'CLICK_SEARCH_BRANDS_NAV_BTN',
  clickShoppingBoardNavBtn: 'CLICK_SHOPPING_BOARD_NAV_BTN',
  clickAccountNavBtn: 'CLICK_ACCOUNT_NAV_BTN',

  // Brand events
  clickBrand: 'CLICK_BRAND',
  hoverBrand: 'HOVER_BRAND',

  // Shopping board events
  addProductToBoard: 'ADD_PRODUCT_TO_BOARD',
  removeProductFromBoard: 'REMOVE_PRODUCT_FROM_BOARD',
  shareProductFromBoard: 'SHARE_PRODUCT_FROM_BOARD',
  shareBoard: 'SHARE_BOARD',
  viewBoard: 'VIEW_BOARD',

  // Account events
  clickSignInBtn: 'CLICK_SIGN_IN_BTN',
  clickSignOutBtn: 'CLICK_SIGN_OUT_BTN',
  gotoProfile: 'GOTO_PROFILE',

  // Cashback events
  activateCashback: 'ACTIVATE_CASHBACK',
}

export const cashbackholicRefKey = 'cashbackholic'
